import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import mainImg from "../../images/services/coach-hire-services-group-tours.jpg"
import NewsStrip from "../../components/news-strip"
import arrowSvg from "../../images/arrow-circle-right-solid.svg"

class ServicePage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Group tour coach hire" description="Book coach hire for your group tour with Tetley's Coaches - Leeds' oldest bus and coach company established 1949." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Group Tours</h1>
            </div>

            <div className="news-article-body">

              <p>Whether you are an overseas group wishing to visit the United Kingdom or a local group planning a tour within the UK, we welcome your enquiries. If you require advice on places of interest, accessibility or drop off locations, our experienced sales team will be happy to assist.</p>
              <p>Our friendly and experienced sales team will be happy to discuss your itinerary and tailor your coach hire requirements and provide a no-obligation, no-pressure, highly competitive quote.</p>
              <p>
                <img className="service-img" src={mainImg} alt="Group tour coach hire" />
              </p>
              <p>We have the coach to match your requirements, your expectations and your budget. Our 16, 33, 49, 57 and 70 seat coaches are fully seat-belted. Our executive series coaches have the added benefit of on-board WC, Wifi, radio with USB connection and air-conditioning.</p>
              <p>A large proportion of our work is repeat business which is no surprise. It's a testament to our high level of customer care, our experience, our vehicles and our staff.</p>
              <p>If you're looking for quality and experience, we're the coach hire company to call. Established in 1949, Tetley's Coaches have provided comfortable, safe and reliable coach transport for over 75 Years.</p>
              <p className="text-center">
                <a className='btn btn-grow btn-quote' href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer">
                  <span className='btn-quote__text'>Get quote</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
              </p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default ServicePage
